import {Box, Button, Grid, InputAdornment, MenuItem, Modal, Select, Typography} from "@material-ui/core";
import {MaterialUiHelpers} from "../../../helpers/MaterialUiHelpers";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {AddPaymentInput, PaymentType, PropertyService, TenantSimplePayload} from "../../../services/openapi";
import {DatePicker} from "@mui/x-date-pickers";

interface IAddPaymentDialogProps {
    propertyId: number;
    open: boolean;
    onClose: () => void;
}

export default function AddPaymentDialog(props: IAddPaymentDialogProps) {
    const [tenants, setTenants] = useState([] as TenantSimplePayload[]);

    const [tenantId, setTenantId] = useState(0);
    const [month, setMonth] = useState(dayjs());
    const [amount, setAmount] = useState(0.0);
    const [type, setType] = useState(PaymentType._0);
    const [expectedDate, setExpectedDate] = useState(dayjs());
    const [paymentDate, setPaymentDate] = useState(null as dayjs.Dayjs | null);

    useEffect(() => {
        PropertyService.propertyGetGetPropertyTenantsSimple(props.propertyId)
            .then(result => setTenants(result));
    }, [props.propertyId]);

    function addPayment() {
        let roomId = tenants.find(q => q.tenantId === tenantId)!.roomId;
        let input = {
            amount: amount,
            month: month.month() + 1,
            paymentDate: paymentDate?.format(),
            expectedDate: expectedDate.format(),
            paymentType: type,
            tenantId: tenantId,
            roomId: roomId,
            year: month.year()
        } as AddPaymentInput;
        PropertyService.propertyPostAddPayment(props.propertyId, input)
            .then(() => {
                props.onClose();

                // reset form
                setTenantId(0);
                setAmount(0);
                setPaymentDate(null);
            });
    }

    return (
        <Modal open={props.open}>
            <Box sx={MaterialUiHelpers.Modal.style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Dodaj płatność
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={5}>Najemca:</Grid>
                    <Grid item xs={7}>
                        <Select
                            variant="standard"
                            fullWidth={true}
                            value={tenantId}
                            onChange={(ev) => setTenantId(parseInt(ev.target.value as string))}
                        >
                            <MenuItem key={0} value={0}>-- wybierz --</MenuItem>
                            {tenants.map(tenant => <MenuItem key={tenant.tenantId}
                                                             value={tenant.tenantId}>{tenant.roomNumber}: {tenant.tenantName}</MenuItem>)}
                        </Select>
                    </Grid>

                    <Grid item xs={5}>Miesiąc:</Grid>
                    <Grid item xs={7}>
                        <DatePicker
                            views={['year', 'month']}
                            openTo="month"
                            value={month}
                            onChange={(newValue) => setMonth(dayjs(newValue))}
                            slotProps={{
                                textField: {
                                    variant: 'standard'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={5}>Kwota:</Grid>
                    <Grid item xs={7}>
                        <TextField
                            variant="standard"
                            value={amount}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">zł</InputAdornment>
                            }}
                            onChange={(ev) => setAmount(ev.target.value ? parseFloat(ev.target.value) : 0.0)}
                        />
                    </Grid>

                    <Grid item xs={5}>Rodzaj:</Grid>
                    <Grid item xs={7}>
                        <Select
                            variant="standard"
                            fullWidth={true}
                            value={type}
                            onChange={(ev) => setType(parseInt(ev.target.value as string))}
                        >
                            <MenuItem key={PaymentType._0} value={PaymentType._0}>Przelew</MenuItem>
                            <MenuItem key={PaymentType._1} value={PaymentType._1}>Gotówka</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={5}>Wymagalna:</Grid>
                    <Grid item xs={7}>
                        <DatePicker
                            value={expectedDate}
                            onChange={(newValue) => {
                                setExpectedDate(dayjs(newValue));
                            }}
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    variant: 'standard'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={5}>Data płatności:</Grid>
                    <Grid item xs={7}>
                        <DatePicker
                            value={paymentDate}
                            onChange={(newValue) => {
                                setPaymentDate(newValue === null ? null : dayjs(newValue));
                            }}
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    variant: 'standard'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={7}>
                        <Button variant="outlined" onClick={() => addPayment()}>Zapisz</Button>
                        <Button variant="outlined" onClick={() => props.onClose()}>Anuluj</Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
}