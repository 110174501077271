import React, {useState} from 'react';
import './App.css';
import {Properties} from './components/properties/Properties';
import {Logout} from "./components/security/Logout";
import {OpenAPI, SecurityService} from './services/openapi';
import {Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {useNavigate} from "react-router";
import {Header} from "./components/core/Header";
import MainMenu from './components/core/MainMenu';
import PropertyGeneral from "./components/properties/PropertyGeneral";
import PropertyOverview from './components/properties/PropertyOverview';
import PropertyTenants from "./components/properties/PropertyTenants";
import PropertySettlements from "./components/properties/PropertySettlements";
import PropertyPayments from "./components/properties/PropertyPayments";
import {Grid} from "@material-ui/core";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Home from "./Home";
import PropertyMessages from "./components/properties/PropertyMessages";

let refreshingToken: boolean = false;

export default function App() {
    const expirationDateFromLocalStorage = localStorage.getItem('expirationDate');
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken')?.toString());
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken')?.toString());
    const [expirationDate, setExpirationDate] = useState(expirationDateFromLocalStorage ? new Date(expirationDateFromLocalStorage) : undefined);

    let navigate = useNavigate();

    OpenAPI.TOKEN = async () => {
        if (refreshingToken || (accessToken && expirationDate && expirationDate! > new Date())) {
            // the token is correct or we are currently refreshing it
            return accessToken!;
        } else if (refreshToken) {
            // refreshing token
            refreshingToken = true;

            await SecurityService
                .securityPostRefreshToken({
                    refreshToken: refreshToken!
                })
                .then(response => {
                    setAccessToken(response.accessToken!);
                    setRefreshToken(response.refreshToken!);
                    setExpirationDate(new Date(response.expiresOn!));

                    localStorage.setItem('accessToken', response.accessToken!);
                    localStorage.setItem('refreshToken', response.refreshToken!);
                    localStorage.setItem('expirationDate', response.expiresOn!);
                    refreshingToken = false;
                })
                .catch(_ => {
                    // error while refreshing the token, redirect to the login page
                    onLogout();
                });

            return accessToken!;
        } else {
            return '';
        }
    };

    let onLogout = function () {
        setAccessToken('');
        setRefreshToken('');
        setExpirationDate(undefined);

        localStorage.setItem('accessToken', '');
        localStorage.setItem('refreshToken', '');
        localStorage.setItem('expirationDate', '');

        navigate("/login");
    }

    if (!accessToken) {
        return <Navigate to="/login" replace={true}/>;
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <div className="height100">
                <Header/>
                <Grid container spacing={0} className="gridApp">
                    <Grid item xs={2} className="height100">
                        <MainMenu/>
                    </Grid>
                    <Grid item xs={10} className="content">
                        <Routes>
                            <Route path="" element={<Home/>}/>
                            <Route path="properties" element={<Outlet/>}>
                                <Route path="" element={<Properties/>}/>
                                <Route path=":propertyId" element={<PropertyOverview/>}/>
                                <Route path=":propertyId/data" element={<PropertyGeneral/>}/>
                                <Route path=":propertyId/tenants" element={<PropertyTenants/>}/>
                                <Route path=":propertyId/settlements" element={<PropertySettlements/>}/>
                                <Route path=":propertyId/payments" element={<PropertyPayments/>}/>
                                <Route path=":propertyId/messages" element={<PropertyMessages/>}/>
                            </Route>
                            <Route path="logout" element={<Logout logoutImmediately={true}/>}/>
                        </Routes>
                    </Grid>
                </Grid>
            </div>
        </LocalizationProvider>
    );
}