import {Box, Button, Grid, Modal, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {PropertyService} from "../../../services/openapi";
import dayjs from "dayjs";
import {MaterialUiHelpers} from "../../../helpers/MaterialUiHelpers";
import {DatePicker} from "@mui/x-date-pickers";

interface IAppPaymentDateDialogProps {
    open: boolean;
    propertyId: number;
    paymentId: number;
    onClose: () => void;
}

export default function AddPaymentDateDialog(props: IAppPaymentDateDialogProps) {
    const [paymentDate, setPaymentDate] = useState(dayjs());

    function savePaymentDate() {
        PropertyService.propertyPutMakePaymentPaid(props.propertyId, props.paymentId, paymentDate.format())
            .then(() => {
                props.onClose();
            });
    }

    return (
        <Modal open={props.open}>
            <Box sx={MaterialUiHelpers.Modal.style}>
                <Typography variant="h6" component="h2">
                    Oznasz jako zapłaconą
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={5}>Data płatności:</Grid>
                    <Grid item xs={7}>
                        <DatePicker
                            value={paymentDate}
                            onChange={(newValue) => {
                                setPaymentDate(dayjs(newValue));
                            }}
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    variant: 'standard'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={7}>
                        <Button variant="outlined" onClick={() => savePaymentDate()}>Zapisz</Button>
                        <Button variant="outlined" onClick={() => props.onClose()}>Anuluj</Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}