import {NavLink} from "react-router-dom";

interface IPropertySubMenuProps {
    propertyId: number
}

export default function PropertySubMenu(props: IPropertySubMenuProps) {
    return (
        <ul>
            <li><NavLink to={`/properties/${props.propertyId}/data`}>Dane ogólne</NavLink></li>
            <li><NavLink to={`/properties/${props.propertyId}/tenants`}>Lokatorzy</NavLink></li>
            <li><NavLink to={`/properties/${props.propertyId}/settlements`}>Rozliczenia</NavLink></li>
            <li><NavLink to={`/properties/${props.propertyId}/payments`}>Wpłaty</NavLink></li>
            <li><NavLink to={`/properties/${props.propertyId}/messages`}>Wiadomości</NavLink></li>
        </ul>
    )
}