import {Box, Button, Grid, Modal, Typography} from "@material-ui/core";
import {MaterialUiHelpers} from "../../../helpers/MaterialUiHelpers";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {useState} from "react";
import {PropertyService} from "../../../services/openapi";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";

interface IAddPaymentInvoiceDialogProps {
    open: boolean;
    propertyId: number;
    paymentId: number;
    paymentDate: Date;
    onClose: () => void;
}

export function AddPaymentInvoiceDialog(props: IAddPaymentInvoiceDialogProps) {
    const [type, setType] = useState("0");
    const [newType, setNewType] = useState("0");
    const [paymentDate, setPaymentDate] = useState(dayjs(props.paymentDate));
    const [invoiceId, setInvoiceId] = useState(0);

    function save() {
        if (type === "0") {
            // new invoice
            if (newType === "0") {
                // based on a template
                PropertyService.propertyPostAddTemplateInvoiceToPayment(props.propertyId, props.paymentId, paymentDate.format(), paymentDate.format())
                    .then(() => {
                        props.onClose();
                    })
                return;
            }
        } else if (type === "1") {
            // existing invoice
            PropertyService.propertyPutAddInvoiceIdToPayment(props.propertyId, props.paymentId, invoiceId!)
                .then(() => {
                    setInvoiceId(0);
                    props.onClose();
                });
            return;
        }

        alert('Nie umiem…');
    }

    return (
        <Modal open={props.open}>
            <Box sx={MaterialUiHelpers.Modal.style}>
                <Typography variant="h6" component="h2">
                    Dodaj fakturę
                </Typography>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <RadioGroup row name="invoiceType" value={type} onChange={(ev) => {
                            setType(ev.target.value);
                        }}>
                            <FormControlLabel value="0" control={<Radio/>}
                                              label="Stwórz nową fakturę"/>
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Przypnij do istniejącej faktury"/>
                        </RadioGroup>
                    </Grid>

                    {/* Existing invoice */}
                    <Grid item xs={12}>
                        <h4 hidden={type === "0"}>Istniejąca faktura</h4>
                    </Grid>
                    <Grid item xs={5} hidden={type === "0"}>ID faktury:</Grid>
                    <Grid item xs={7} hidden={type === "0"}>
                        <TextField variant="standard" value={invoiceId}
                                   onChange={(ev) => setInvoiceId(ev.target.value ? parseInt(ev.target.value) : 0)}></TextField>
                    </Grid>

                    {/* New invoice */}
                    <h4 hidden={type !== "0"}>Nowa faktura</h4>
                    <Grid item xs={12} hidden={type !== "0"}>
                        <RadioGroup row name="newInvoiceType" value={newType} onChange={(ev) => {
                            setNewType(ev.target.value);
                        }}>
                            <FormControlLabel value="0" control={<Radio/>}
                                              label="Na podstawie istniejącego szablonu"/>
                            <FormControlLabel value="1" control={<Radio/>}
                                              label="Podając wszystkie dane"
                                              disabled={true}/>
                        </RadioGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <h5 hidden={type !== "0" && newType !== "1"}>Dane do faktury</h5>
                    </Grid>
                    <Grid item xs={5} hidden={type !== "0" && newType !== "1"}>
                        Data wystawienia:
                    </Grid>
                    <Grid item xs={7} hidden={type !== "0" && newType !== "1"}>
                        <DatePicker
                            value={paymentDate}
                            onChange={(newValue) => {
                                setPaymentDate(dayjs(newValue));
                            }}
                            format="YYYY-MM-DD"
                            slotProps={{
                                textField: {
                                    variant: 'standard'
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs={5}></Grid>
                    <Grid item xs={7}>
                        <Button variant="outlined" onClick={() => save()}>Zapisz</Button>
                        <Button variant="outlined" onClick={() => props.onClose()}>Anuluj</Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}