import * as React from 'react';
import {useEffect, useState} from 'react';
import {PropertyPayload, PropertyService} from '../../services/openapi'
import {NavLink} from "react-router-dom";

export function Properties() {
    const [properties, setProperties] = useState([] as PropertyPayload[]);
    useEffect(() => {
        PropertyService.propertyGetGetAll()
            .then(props => {
                setProperties(props);
            });
    }, []);

    return (
        <div>
            <h1>Nieruchomości</h1>
            <ul>
                {properties.map(prop => (
                    <li key={prop.id}><NavLink to={`${prop.id}`}>{prop.fullName}</NavLink></li>
                ))}
            </ul>
        </div>
    )
}