import {faEnvelope, faMobile} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";

interface ISendMessageProps {
    propertyId: number;
}

export default function SendMessage(props: ISendMessageProps) {
    const [typeEmail, sendEmail] = useState(false);
    const [typeSms, sendSms] = useState(false);

    return (
        <div>
            <h5>W jaki sposób wysłać wiadomość?</h5>
            <p>
                <span onClick={() => { sendEmail(true); sendSms(false); }} style={{ cursor:  "pointer", fontWeight: typeEmail ? "bold" : "normal" }}><span><FontAwesomeIcon icon={faEnvelope} /> E-mail</span></span>
                <span onClick={() => { sendSms(true); sendEmail(false); }} style={{ cursor:  "pointer", fontWeight: typeSms ? "bold" : "normal", marginLeft: "40px" }}><span><FontAwesomeIcon icon={faMobile} /> SMS</span></span>
            </p>
            <div hidden={!typeEmail}>E-mail</div>
            <div hidden={!typeSms}>SMS</div>
        </div>
    );
};