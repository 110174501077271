/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessagePayloadTableWrapper } from '../models/MessagePayloadTableWrapper';
import type { OrderByMessage } from '../models/OrderByMessage';
import type { OrderDirection } from '../models/OrderDirection';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessageService {

    /**
     * @param propertyId 
     * @param page 
     * @param orderBy 
     * @param orderDirection 
     * @param pageSize 
     * @returns MessagePayloadTableWrapper Success
     * @throws ApiError
     */
    public static messageGetGetPropertyMessages(
propertyId: number,
page?: number,
orderBy?: OrderByMessage,
orderDirection?: OrderDirection,
pageSize?: number,
): CancelablePromise<MessagePayloadTableWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Message/property/{propertyId}',
            path: {
                'propertyId': propertyId,
            },
            query: {
                'Page': page,
                'OrderBy': orderBy,
                'OrderDirection': orderDirection,
                'PageSize': pageSize,
            },
        });
    }

}
