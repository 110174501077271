import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PropertyPayload, PropertyService} from "../../services/openapi";
import PropertySubMenu from "../core/PropertySubMenu";

export default function PropertyGeneral() {
    let params = useParams();
    const [property, setProperty] = useState({} as PropertyPayload);

    useEffect(() => {
        PropertyService.propertyGetGet(parseInt(params.propertyId!))
            .then(property => {
                setProperty(property);
            });
    }, [params.propertyId])

    return (
        <div>
            <h1>{property.fullName}</h1>
            <PropertySubMenu propertyId={property.id!}/>
        </div>
    )
}