import React, {useEffect, useState} from "react";
import {PropertyPayload, PropertyService} from "./services/openapi";
import {NavLink} from "react-router-dom";
import {Logout} from "./components/security/Logout";

export default function Home() {
    const [properties, setProperties] = useState([] as PropertyPayload[]);

    useEffect(() => {
        PropertyService.propertyGetGetAll()
            .then(properties => {
                setProperties(properties);
            });
    }, [])

    return (
        <div>
            <h1>Nieruchomości</h1>
            <ul>
                {properties.map(prop => {
                    return (<li key={prop.id}>
                        <NavLink to={`properties/${prop.id}`}>{prop.fullName}</NavLink>
                    </li>)
                })}
            </ul>
            <h1>Ustawienia</h1>
            <Logout logoutImmediately={false}/>
        </div>
    )
}