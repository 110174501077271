import * as React from 'react';
import {useEffect, useState} from 'react';
import {Logout} from '../security/Logout';
import {UserService} from '../../services/openapi'
import styles from './Header.module.css';

export function Header() {
    const [login, setLogin] = useState('');
    useEffect(() => {
        UserService.userGetGetMe()
            .then(me => {
                if (me) {
                    setLogin(me.login!);
                }
            })
    }, [login]);

    return (
        <div id={styles.header}>
            <div>Witaj {login}!&nbsp;
                <Logout logoutImmediately={false}/>
            </div>
        </div>
    )
}