import {useEffect, useRef, useState} from "react";
import {MessageService, PropertyPayload, PropertyService} from "../../services/openapi";
import {useParams} from "react-router-dom";
import {MaterialUiHelpers} from "../../helpers/MaterialUiHelpers";
import MaterialTable from "@material-table/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobile} from "@fortawesome/free-solid-svg-icons";
import {Box, Button, Modal, Typography} from "@material-ui/core";
import SendMessage from "../messages/wizard/Intro";

export default function PropertyMessages() {
    const [property, setProperty] = useState({} as PropertyPayload);
    const [sendMessageDialogOpen, openSendMessageDialog] = useState(false);
    const params = useParams();
    const tableRef = useRef(null as any);

    useEffect(() => {
        // table refresh
        tableRef.current.onQueryChange();
    })

    useEffect(() => {
        // getting data
        PropertyService.propertyGetGet(parseInt(params.propertyId!))
            .then(result => {
                setProperty(result);
            });
    }, [params.propertyId])

    return (
        <div>
            <Modal open={sendMessageDialogOpen}>
                <Box sx={MaterialUiHelpers.Modal.style} style={{width: "80%"}}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Wyślij wiadomość
                    </Typography>
                    <SendMessage
                        propertyId={parseInt(params.propertyId!)}/>
                </Box>
            </Modal>

            <h1>{property.fullName}</h1>
            <h2>Wiadomości</h2>

            <MaterialTable
                tableRef={tableRef}
                options={MaterialUiHelpers.Table.options}
                localization={MaterialUiHelpers.Table.localization}
                columns={[
                    {title: 'ID', field: 'id', hidden: true},
                    {title: 'Pokój', field: 'roomNumber', align: 'center', width: 30},
                    {title: 'Imię', field: 'firstName', width: 50},
                    {title: 'Nazwisko', field: 'lastName', width: 50},
                    {title: 'Data', field: 'date', align: 'center', width: 200},
                    {
                        title: 'Typ', field: 'type', width: 20, render: (rowData) => {
                            return (rowData.type === 0
                                    ? <FontAwesomeIcon icon={faEnvelope} title="E-mail"/>
                                    : <FontAwesomeIcon icon={faMobile} title="SMS"/>
                            )
                        }
                    },
                    {title: 'Temat', field: 'subject'},
                    {title: 'Treść', field: 'body'}
                ]}
                data={query =>
                    new Promise((resolve, reject) => {
                        let orderBy = query.orderBy === undefined ? "date" : query.orderBy?.field!.toString();
                        let orderDirection = query.orderDirection ? query.orderDirection.toString() : "asc";
                        MessageService.messageGetGetPropertyMessages(parseInt(params.propertyId!), query.page, orderBy as any, orderDirection as any, query.pageSize)
                            .then(result => {
                                resolve({
                                    data: result.data as any,
                                    page: query.page,
                                    totalCount: result.count!,
                                });
                            });
                    })
                }
            />
            <p><Button variant="outlined" onClick={() => openSendMessageDialog(true)}>Wyślij wiadomość</Button></p>
        </div>
    )
}