export let MaterialUiHelpers = {
    Modal: {
        style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }
    },
    Table: {
        options: {
            search: false,
            pageSize: 10,
            showTitle: false,
            toolbar: false
        },
        localization: {
            body: {
                emptyDataSourceMessage: 'Brak rekordów do wyświetlenia'
            },
            pagination: {
                labelRowsSelect: 'wierszy',
                labelDisplayedRows: '{from}-{to} z {count}',
                firstTooltip: 'Pierwsza strona',
                previousTooltip: 'Poprzednia strona',
                nextTooltip: 'Następna strona',
                lastTooltip: 'Ostatnia strona'
            }
        },
        columns: {
            currencySettings: {
                currencyCode: 'PLN',
                locale: 'pl'
            }
        }
    }
};