/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginInputModel } from '../models/LoginInputModel';
import type { RefreshTokenInput } from '../models/RefreshTokenInput';
import type { TokenPayload } from '../models/TokenPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SecurityService {

    /**
     * @param requestBody 
     * @returns TokenPayload Success
     * @throws ApiError
     */
    public static securityPostAuthenticate(
requestBody?: LoginInputModel,
): CancelablePromise<TokenPayload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Security/Authenticate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns TokenPayload Success
     * @throws ApiError
     */
    public static securityPostRefreshToken(
requestBody?: RefreshTokenInput,
): CancelablePromise<TokenPayload> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Security/RefreshToken',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public static securityPostLogout(): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Security/Logout',
        });
    }

}
