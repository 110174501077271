import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';

import styles from './Login.module.css';
import {SecurityService} from '../../services/openapi';
import {Button, Grid, TextField} from "@material-ui/core";


export interface ILoginProps {
    logged?: boolean;
}

export function Login(props: ILoginProps) {
    let navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [incorrect, setIncorrect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    const handleLoginChange = function (ev: React.ChangeEvent<HTMLInputElement>) {
        setLogin(ev.target.value);
    }

    const handlePasswordChange = function (ev: React.ChangeEvent<HTMLInputElement>) {
        setPassword(ev.target.value);
    }

    const handleSubmit = function (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault();

        setIncorrect(false);
        setLoading(true);

        const updateState = (incorrect: boolean, loading: boolean, succeeded: boolean) => {
            setIncorrect(incorrect);
            setLoading(loading);
            setSucceeded(succeeded);
        }

        SecurityService
            .securityPostAuthenticate({
                login: login,
                password: password
            })
            .then(response => {
                updateState(false, false, true);

                localStorage.setItem('accessToken', response.accessToken!);
                localStorage.setItem('refreshToken', response.refreshToken!);
                localStorage.setItem('expirationDate', response.expiresOn!.toString());

            })
            .catch(_ => {
                updateState(true, false, false);
            });
    }

    useEffect(() => {
        if (succeeded) {
            navigate('/');
        }
    });

    let message;
    if (incorrect) {
        message = <p className={styles.error}>Hasło jest niepoprawne.</p>
    } else {
        message = null;
    }

    let loginForm = (
        <Grid item xs={12} md={6} xl={5} className={styles.loginRight}>
            <h2>Zaloguj się</h2>
            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                        <label htmlFor="name">Nazwa użytkownika:</label>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField type="text" name="login" value={login} onChange={handleLoginChange}
                                   autoFocus={true}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <label htmlFor="password">Hasło:</label>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField type="password" name="password" value={password} onChange={handlePasswordChange}/>
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                    <Grid item xs={12} md={9}>
                        <Button variant="outlined" type="submit"
                                className={loading ? "button buttonLoadable button--loading" : "button buttonLoadable"}
                                onClick={(e) => handleSubmit(e)}>
                            <span className="button__text">Zaloguj</span>
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {message}
        </Grid>
    );

    return (
        <Grid container spacing={0} id={styles.login}>
            <Grid item xs={12} md={6} xl={7}>
                <div className={styles.loginLeft}>
                    <img
                        srcSet="/logo192.png 192w, /logo360.png 360w, /logo512.png 512w"
                        sizes="(max-width: 400px) 192px, (max-width: 800px) 360px, 512px"
                        src="/logo.png"
                        alt="wynajmujesz.pl"/>
                </div>
            </Grid>
            {loginForm}
        </Grid>
    );
}