import {useParams} from "react-router-dom";
import MaterialTable from "@material-table/core";
import {PropertyPayload, PropertyService} from "../../services/openapi";
import {useEffect, useRef, useState} from "react";
import AddPaymentDateDialog from "./dialogs/AddPaymentDateDialog";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import {MaterialUiHelpers} from "../../helpers/MaterialUiHelpers";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope, faFileInvoiceDollar, faReceipt} from "@fortawesome/free-solid-svg-icons";
import AddPaymentDialog from "./dialogs/AddPaymentDialog";
import {AddPaymentInvoiceDialog} from "./dialogs/AddPaymentInvoiceDialog";

export default function PropertyPayments() {
    const [property, setProperty] = useState({} as PropertyPayload);
    const [onlyUnprocessed, setOnlyUnprocessed] = useState(true);
    const [currentPaymentId, setCurrentPaymentId] = useState(0);
    const [addPaymentDialogOpen, openAddPaymentDialog] = useState(false);
    const [addPaymentDateDialogOpen, openAddPaymentDateDialog] = useState(false);
    const [addPaymentInvoiceDialogOpen, openAddPaymentInvoiceDialog] = useState(false);
    const params = useParams();
    const tableRef = useRef(null as any);

    useEffect(() => {
        // table refresh
        tableRef.current.onQueryChange();
    })

    useEffect(() => {
        // getting data
        PropertyService.propertyGetGet(parseInt(params.propertyId!))
            .then(result => {
                setProperty(result);
            });
    }, [params.propertyId])

    return (
        <>
            <h1>{property.fullName}</h1>
            <h2>Wpłaty</h2>
            <p><FormControlLabel
                control={<Checkbox checked={onlyUnprocessed} onChange={(e) => setOnlyUnprocessed(e.target.checked)}/>}
                label="Pokaż tylko niezakończone"/></p>

            {/* Dialogs */}
            <AddPaymentDialog
                open={addPaymentDialogOpen}
                propertyId={parseInt(params.propertyId!)}
                onClose={() => openAddPaymentDialog(false)}/>

            <AddPaymentDateDialog
                open={addPaymentDateDialogOpen}
                propertyId={property.id!}
                paymentId={currentPaymentId}
                onClose={() => openAddPaymentDateDialog(false)}/>

            <AddPaymentInvoiceDialog
                open={addPaymentInvoiceDialogOpen}
                propertyId={property.id!}
                paymentId={currentPaymentId}
                paymentDate={new Date()}
                onClose={() => openAddPaymentInvoiceDialog(false)}
            />

            <MaterialTable
                tableRef={tableRef}
                options={MaterialUiHelpers.Table.options}
                localization={MaterialUiHelpers.Table.localization}
                columns={[
                    {title: 'ID', field: 'id', hidden: true},
                    {title: 'Pokój', field: 'roomNumber', align: 'center'},
                    {title: 'Lokator', field: 'tenantName'},
                    {title: 'Rok', field: 'year', align: 'center'},
                    {title: 'Miesiąc', field: 'month', align: 'center'},
                    {
                        title: 'Kwota',
                        field: 'amount',
                        align: 'right',
                        type: 'currency',
                        currencySetting: MaterialUiHelpers.Table.columns.currencySettings
                    },
                    {title: 'Termin płatności', field: 'expectedDate', align: 'center'},
                    {title: 'Data zapłaty', field: 'paymentDate', align: 'center'},
                    {
                        title: 'Numer faktury', field: 'invoiceNumber', align: 'right',
                        render: rowData => <a href={rowData.invoicePreviewLink} target="_blank"
                                              rel="noreferrer">{rowData.invoiceNumber}</a>
                    },
                    {
                        title: '', width: 20, render: (rowData) => {
                            return (rowData.paymentDate === null ? (
                                <FontAwesomeIcon icon={faFileInvoiceDollar} title="Oznacz jako zapłaconą"
                                                 onClick={() => {
                                                     setCurrentPaymentId(rowData.id);
                                                     openAddPaymentDateDialog(true);
                                                 }}/>) : <></>)
                        }
                    },
                    {
                        title: '', width: 20, render: (rowData) => {
                            return (rowData.paymentDate === null || rowData.invoiceNumber === null ? (
                                <FontAwesomeIcon icon={faReceipt} title="Dodaj fakturę"
                                                 onClick={() => {
                                                     setCurrentPaymentId(rowData.id);
                                                     openAddPaymentInvoiceDialog(true);
                                                 }}/>) : <></>)
                        }
                    },
                    {
                        title: '', width: 20, render: (rowData) => {
                            return (rowData.invoiceNumber !== null && rowData.invoiceSent !== true ? (
                                <FontAwesomeIcon icon={faEnvelope} title="Wyślij fakturę e-mailem"
                                                 onClick={() => {
                                                     PropertyService.propertyPostSendPaymentInvoiceByEmail(property.id!, rowData.id!)
                                                         .then(() => tableRef.current.onQueryChange());
                                                 }}/>) : <></>)
                        }
                    },
                    {title: 'Bizin', field: 'invoicePreviewLink', hidden: true},
                    {title: 'Bizin', field: 'invoiceSent', hidden: true}
                ]}
                data={query =>
                    new Promise((resolve, reject) => {
                        let orderBy = query.orderBy === undefined ? "expectedDate" : query.orderBy?.field!.toString();
                        let orderDirection = query.orderDirection ? query.orderDirection.toString() : "asc";
                        PropertyService.propertyGetGetPropertyPayments(parseInt(params.propertyId!), query.page, orderBy as any, orderDirection as any, query.pageSize, onlyUnprocessed)
                            .then(result => {
                                resolve({
                                    data: result.data as any,
                                    page: query.page,
                                    totalCount: result.count!,
                                });
                            });
                    })
                }
            />
            <p><Button variant="outlined" onClick={() => openAddPaymentDialog(true)}> Dodaj płatność</Button>
            </p>
        </>
    )
}