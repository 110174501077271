import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import styles from "./MainMenu.module.css";
import {PropertyPayload, PropertyService} from "../../services/openapi";
import PropertySubMenu from "./PropertySubMenu";

export default function MainMenu() {
    const [properties, setProperties] = useState([] as PropertyPayload[]);

    useEffect(() => {
        PropertyService.propertyGetGetAll()
            .then(properties => {
                setProperties(properties);
            });
    }, [])

    return (
        <nav id={styles.mainMenu} className="height100">
            <p>
                <NavLink to={`/`}>
                    <img src="/logo192.png" alt="wynajmujesz.pl"/>
                </NavLink>
            </p>
            <div><NavLink to="properties">Nieruchomości</NavLink></div>
            <ul>
                {properties.map(prop => {
                    return (<li key={prop.id}>
                        <NavLink to={`properties/${prop.id}`}>{prop.fullName}</NavLink>
                        <PropertySubMenu propertyId={prop.id!}/>
                    </li>)
                })}
            </ul>
        </nav>
    );
}