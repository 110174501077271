/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserPayload } from '../models/UserPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @returns UserPayload Success
     * @throws ApiError
     */
    public static userGetGetMe(): CancelablePromise<UserPayload> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/User/me',
        });
    }

}
