import {useParams} from "react-router-dom";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {useEffect, useRef, useState} from "react";
import {PropertyPayload, PropertyService, TenantService} from "../../services/openapi";
import {MaterialUiHelpers} from "../../helpers/MaterialUiHelpers";
import MaterialTable from "@material-table/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faX} from "@fortawesome/free-solid-svg-icons";

export default function PropertyTenants() {
    const [property, setProperty] = useState({} as PropertyPayload);
    const [onlyCurrent, setOnlyCurrent] = useState(true);
    const params = useParams();
    const tableRef = useRef(null as any);

    useEffect(() => {
        // getting data
        PropertyService.propertyGetGet(parseInt(params.propertyId!))
            .then(result => {
                setProperty(result);
            });
    }, [params.propertyId]);

    useEffect(() => {
        // table refresh
        tableRef.current.onQueryChange();
    }, [onlyCurrent, params.propertyId]);

    return (
        <>
            <h1>{property.fullName}</h1>
            <h2>Lokatorzy</h2>
            <p><FormControlLabel
                control={<Checkbox checked={onlyCurrent} onChange={(e) => setOnlyCurrent(e.target.checked)}/>}
                label="Pokaż tylko aktualnych"/></p>

            <MaterialTable
                tableRef={tableRef}
                options={{
                    ...MaterialUiHelpers.Table.options,
                    paging: false,
                    sorting: false
                }}
                localization={MaterialUiHelpers.Table.localization}
                columns={[
                    {title: 'ID', field: 'id', hidden: true},
                    {title: 'Imię', field: 'firstName'},
                    {title: 'Nazwisko', field: 'lastName'},
                    {title: 'Pokój', field: 'roomNumber'},
                    {
                        title: 'Aktualny',
                        field: 'current',
                        render: (rowData) => {
                            return (rowData.current === true
                                    ? (<FontAwesomeIcon icon={faCheck}/>)
                                    : (<FontAwesomeIcon icon={faX}/>)
                            )
                        }
                    },
                ]}
                data={query =>
                    new Promise((resolve, reject) => {
                        TenantService.tenantGetGetForProperty(parseInt(params.propertyId!), onlyCurrent)
                            .then(result => {
                                resolve({
                                    data: result as any,
                                    page: 0,
                                    totalCount: result.length,
                                });
                            });
                    })
                }
            />
        </>
    )
}