/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddPaymentInput } from '../models/AddPaymentInput';
import type { OrderByPayment } from '../models/OrderByPayment';
import type { OrderDirection } from '../models/OrderDirection';
import type { PropertyPayload } from '../models/PropertyPayload';
import type { PropertyPaymentPayloadTableWrapper } from '../models/PropertyPaymentPayloadTableWrapper';
import type { TenantSimplePayload } from '../models/TenantSimplePayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PropertyService {

    /**
     * @returns PropertyPayload Success
     * @throws ApiError
     */
    public static propertyGetGetAll(): CancelablePromise<Array<PropertyPayload>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Property',
        });
    }

    /**
     * @param propertyId 
     * @returns PropertyPayload Success
     * @throws ApiError
     */
    public static propertyGetGet(
propertyId: number,
): CancelablePromise<PropertyPayload> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Property/{propertyId}',
            path: {
                'propertyId': propertyId,
            },
        });
    }

    /**
     * @param propertyId 
     * @param page 
     * @param orderBy 
     * @param orderDirection 
     * @param pageSize 
     * @param onlyUnprocessed 
     * @returns PropertyPaymentPayloadTableWrapper Success
     * @throws ApiError
     */
    public static propertyGetGetPropertyPayments(
propertyId: number,
page?: number,
orderBy?: OrderByPayment,
orderDirection?: OrderDirection,
pageSize?: number,
onlyUnprocessed?: boolean,
): CancelablePromise<PropertyPaymentPayloadTableWrapper> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Property/{propertyId}/payments',
            path: {
                'propertyId': propertyId,
            },
            query: {
                'Page': page,
                'OrderBy': orderBy,
                'OrderDirection': orderDirection,
                'PageSize': pageSize,
                'onlyUnprocessed': onlyUnprocessed,
            },
        });
    }

    /**
     * @param propertyId 
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static propertyPostAddPayment(
propertyId: number,
requestBody?: AddPaymentInput,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Property/{propertyId}/payments',
            path: {
                'propertyId': propertyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param propertyId 
     * @param paymentId 
     * @param paymentDate 
     * @returns any Success
     * @throws ApiError
     */
    public static propertyPutMakePaymentPaid(
propertyId: number,
paymentId: number,
paymentDate?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Property/{propertyId}/payments/{paymentId}/paid',
            path: {
                'propertyId': propertyId,
                'paymentId': paymentId,
            },
            query: {
                'paymentDate': paymentDate,
            },
        });
    }

    /**
     * @param propertyId 
     * @param paymentId 
     * @param invoiceId 
     * @returns any Success
     * @throws ApiError
     */
    public static propertyPutAddInvoiceIdToPayment(
propertyId: number,
paymentId: number,
invoiceId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/Property/{propertyId}/payments/{paymentId}/invoiceid',
            path: {
                'propertyId': propertyId,
                'paymentId': paymentId,
            },
            query: {
                'invoiceId': invoiceId,
            },
        });
    }

    /**
     * @param propertyId 
     * @param paymentId 
     * @param issueDate 
     * @param paymentDate 
     * @returns any Success
     * @throws ApiError
     */
    public static propertyPostAddTemplateInvoiceToPayment(
propertyId: number,
paymentId: number,
issueDate?: string,
paymentDate?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Property/{propertyId}/payments/{paymentId}/invoice/template',
            path: {
                'propertyId': propertyId,
                'paymentId': paymentId,
            },
            query: {
                'issueDate': issueDate,
                'paymentDate': paymentDate,
            },
        });
    }

    /**
     * @param propertyId 
     * @param paymentId 
     * @returns any Success
     * @throws ApiError
     */
    public static propertyPostSendPaymentInvoiceByEmail(
propertyId: number,
paymentId: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/Property/{propertyId}/payments/{paymentId}/invoice/email',
            path: {
                'propertyId': propertyId,
                'paymentId': paymentId,
            },
        });
    }

    /**
     * @param propertyId 
     * @returns TenantSimplePayload Success
     * @throws ApiError
     */
    public static propertyGetGetPropertyTenantsSimple(
propertyId: number,
): CancelablePromise<Array<TenantSimplePayload>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/Property/{propertyId}/tenants/simple',
            path: {
                'propertyId': propertyId,
            },
        });
    }

}
