import * as React from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {SecurityService} from '../../services/openapi';

interface ILogoutProps {
    logoutImmediately: boolean
}

export function Logout(props: ILogoutProps) {
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    let onLogout = function (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null) {

        e?.preventDefault();

        setLoading(true);

        SecurityService.securityPostLogout()
            .then(_ => {
                setLoading(false);

                localStorage.setItem('accessToken', '');
                localStorage.setItem('refreshToken', '');
                localStorage.setItem('expirationDate', '');

                navigate("/login");
            });
    }

    useEffect(() => {
        if (props.logoutImmediately) {
            onLogout(null);
        }
    });

    return (
        <><a href="/logout"
             className={loading ? "buttonLoadable button--loading button--loading--white" : "buttonLoadable"}
             onClick={onLogout}>Wyloguj</a></>
    )
}